
/***
 * GERAL
 ***/
$(function() {
    $('.animsition').animsition().one('animsition.inStart',function(){
        App.SetToggle();
        App.SetGaleria();
        App.SetBanner();
        App.SetCarouselMobile();
        App.SetHeaderFixed(80);
        App.SetValidate();
        App.SetMaskInput();
        App.SetInputError();
        App.SetFormSuccess();
        App.SetAnimatescroll(150);
        App.SetWow(200);

        $('html').addClass('loaded');

        // open video popup
        $('.open-video').magnificPopup({
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });

        // Cookies
        if (localStorage.hasOwnProperty('verify_cookies')) {
            $('.Cookies').addClass('_hide');
        }

        $('.Cookies .Button').click(function(event) {
            event.preventDefault();
            localStorage.setItem('verify_cookies', true);
            $('.Cookies').addClass('_hide');
        });

        // Tabs servicos
        $('.tabs .tab').click(function(event) {
            event.preventDefault();
        
            target = $(this).data('id');

            $('.tabs .tab').removeClass('_active');
            $(this).addClass('_active');
        
            $('.tab-item').addClass('_hide');
            $(target).removeClass('_hide');
        });
        $('[data-component=bannerS]').each(function(index, el) {
            $(this).addClass('owl-carousel');
            $(this).owlCarousel({
                loop            : true,
                items           : 2,
                autoplay        : true,
                autoplayTimeout : $(this).data("autoplay-timeout"),
                nav             : $(this).data("nav"),
                animateOut      : 'fadeOut',
                navText         : ['<i class="icon-left"></i>', '<i class="icon-right"></i>'],
                dots            : true,
                center          : true,
                margin          : 50
                
            });
        });

        // Carousel servicos mobile
        var owl = $('.servicos, .mobile-carousel');

        owl.on('changed.owl.carousel', function(event) {
            var current = event.item.index;
            var hash    = $(event.target).find('.owl-item').eq(current).find('.servico, .Row').data('owl-hash');
            $('.mobile-carousel, .servicos').trigger('to.owl.carousel', hash);
        });

      
        $(".clickToggle").click(function (event) {
            event.preventDefault();
            $($(this).attr('href')).toggleClass('_hide');
        });


        
    });
});